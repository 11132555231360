import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mb-5"},[_c(VCardText,{staticClass:"py-0 px-0 px-md-4 py-md-4"},[_c(VDataTable,{ref:"tabelaCompras",attrs:{"dense":"","show-expand":"","single-expand":"","item-key":"row-key","expanded":_vm.expanded,"headers":_vm.headers,"items":_vm.data,"loading":_vm.loading,"no-data-text":_vm.noData,"items-per-page":_vm.$dataTableConfig.getItemsPerPageCustom(),"footer-props":_vm.$dataTableConfig.getFooterProps(),"loading-text":_vm.$dataTableConfig.getLoadText(),"mobile-breakpoint":0},on:{"click:row":function (item, slot) { return slot.expand(!slot.isExpanded); },"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"body.append",fn:function(ref){
var items = ref.items;
return [_c('tr',_vm._l((_vm.headers),function(header){return _c('th',{class:header.class,style:({ 'text-align': header.align !== 'right'? 'left' : 'right' })},[(header.total === 'title')?_c('span',[_vm._v("Total")]):(header.total === 'sum')?_c('span',[_vm._v(_vm._s(typeof header.totalFormat === 'function'? header.totalFormat(_vm.data.reduce(function (sum, item) { return item[header.value] + sum; }, 0)): _vm.data.reduce(function (sum, item) { return item[header.value] + sum; }, 0)))]):(header.total === 'count')?_c('span',[_vm._v(_vm._s(typeof header.totalFormat === 'function'? header.totalFormat(_vm.data.length): _vm.data.length))]):_c('span',[_vm._v(" ")])])}),0)]}},{key:"progress",fn:function(){return [_c(VProgressLinear,{attrs:{"absolute":"","indeterminate":"","color":"green"}})]},proxy:true},{key:"item.TipoOcSc",fn:function(ref){
var item = ref.item;
return [_c(VChip,{staticClass:"font-weight-medium",attrs:{"color":_vm.getTypeByTns(item.codtns).color,"dark":"","small":""}},[_vm._v(" "+_vm._s(_vm.getTypeByTns(item.codtns).text)+" ")])]}},{key:"item.Etapa",fn:function(ref){
var item = ref.item;
return [_c(VChip,{staticClass:"font-weight-medium",class:item.status_color === '#FBC02D' ? 'black--text' : '',attrs:{"color":_vm.getTypeByTns(item.codtns).text[2] !== 'R' ? item.status_color : '#8E24AA',"dark":"","small":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.codigo_oc",fn:function(ref){
var item = ref.item;
return [_c('span',{style:([5, 20].includes(item.status_code) ? 'color:red': '')},[_vm._v(_vm._s(item.codigo_oc || '0'))])]}},{key:"item.codigo_sc",fn:function(ref){
var item = ref.item;
return [_c('span',{style:([5, 20].includes(item.status_code) ? 'color:red': '')},[_vm._v(_vm._s(item.codigo_sc || '0'))])]}},{key:"item.data_emissao_sc",fn:function(ref){
var item = ref.item;
return [_c('span',{style:([5, 20].includes(item.status_code) ? 'color:red': '')},[_vm._v(_vm._s(_vm.validDate(item.data_emissao_sc)))])]}},{key:"item.data_aprovacao_sc",fn:function(ref){
var item = ref.item;
return [_c('span',{style:([5, 20].includes(item.status_code) ? 'color:red': '')},[_vm._v(_vm._s(_vm.validDate(item.data_aprovacao_sc)))])]}},{key:"item.data_emissao_oc",fn:function(ref){
var item = ref.item;
return [_c('span',{style:([5, 20].includes(item.status_code) ? 'color:red': '')},[_vm._v(_vm._s(_vm.validDate(item.data_emissao_oc)))])]}},{key:"item.data_aprovacao_nivel_1_oc",fn:function(ref){
var item = ref.item;
return [_c('span',{style:([5, 20].includes(item.status_code) ? 'color:red': '')},[_vm._v(_vm._s(_vm.validDate(item.data_aprovacao_nivel_1_oc)))])]}},{key:"item.data_aprovacao_nivel_2_oc",fn:function(ref){
var item = ref.item;
return [_c('span',{style:([5, 20].includes(item.status_code) ? 'color:red': '')},[_vm._v(_vm._s(_vm.validDate(item.data_aprovacao_nivel_2_oc)))])]}},{key:"item.last_datapr",fn:function(ref){
var item = ref.item;
return [_c('span',{style:([5, 20].includes(item.status_code) ? 'color:red': '')},[_vm._v(_vm._s(_vm.validDate(item.last_datapr)))])]}},{key:"item.last_abrnap",fn:function(ref){
var item = ref.item;
return [_c('span',{style:([5, 20].includes(item.status_code) ? 'color:red': '')},[_vm._v(_vm._s(item.last_abrnap))])]}},{key:"item.next_abrnap",fn:function(ref){
var item = ref.item;
return [_c('span',{style:([5, 20].includes(item.status_code) ? 'color:red': '')},[_vm._v(_vm._s(item.next_abrnap))])]}},{key:"item.data_compra_oc",fn:function(ref){
var item = ref.item;
return [_c('span',{style:([5, 20].includes(item.status_code) ? 'color:red': '')},[_vm._v(_vm._s(_vm.validDate(item.data_compra_oc)))])]}},{key:"item.data_entrega",fn:function(ref){
var item = ref.item;
return [_c('span',{style:([5, 20].includes(item.status_code) ? 'color:red': '')},[_vm._v(_vm._s(_vm.validDate(item.data_entrega)))])]}},{key:"item.data_vencimento_nf",fn:function(ref){
var item = ref.item;
return [_c('span',{style:([5, 20].includes(item.status_code) ? 'color:red': '')},[_vm._v(_vm._s(_vm.validDate(item.data_vencimento_nf)))])]}},{key:"item.data_cancelada_sc",fn:function(ref){
var item = ref.item;
return [_c('span',{style:([5, 20].includes(item.status_code) ? 'color:red': '')},[_vm._v(_vm._s(_vm.validDate(item.data_cancelada_sc)))])]}},{key:"item.data_emissao_nf",fn:function(ref){
var item = ref.item;
return [_c('span',{style:([5, 20].includes(item.status_code) ? 'color:red': '')},[_vm._v(_vm._s(_vm.validDate(item.data_emissao_nf)))])]}},{key:"item.data_vencimento_aberto_nf",fn:function(ref){
var item = ref.item;
return [_c('span',{style:([5, 20].includes(item.status_code) ? 'color:red': '')},[_vm._v(_vm._s(_vm.validDate(item.data_vencimento_aberto_nf)))])]}},{key:"item.producao",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap",style:([5, 20].includes(item.status_code) ? 'color:red': '')},[_vm._v(_vm._s(item.producao))])]}},{key:"item.fornecedor_id",fn:function(ref){
var item = ref.item;
return [_c('span',{style:([5, 20].includes(item.status_code) ? 'color:red': '')},[_vm._v(_vm._s(item.fornecedor_id))])]}},{key:"item.solicitante",fn:function(ref){
var item = ref.item;
return [_c('span',{style:([5, 20].includes(item.status_code) ? 'color:red': '')},[_vm._v(_vm._s(item.solicitante))])]}},{key:"item.fornecedor",fn:function(ref){
var item = ref.item;
return [_c('span',{style:([5, 20].includes(item.status_code) ? 'color:red': '')},[_vm._v(_vm._s(item.fornecedor))])]}},{key:"item.itens_total",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"num_margin",style:([5, 20].includes(item.status_code) ? 'color:red': '')},[_vm._v(_vm._s(item.itens_total))])]}},{key:"item.quantidade_entregas",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"num_margin",style:([5, 20].includes(item.status_code) ? 'color:red': '')},[_vm._v(_vm._s(item.quantidade_entregas))])]}},{key:"item.quantidade_parcelas",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"num_margin",style:([5, 20].includes(item.status_code) ? 'color:red': '')},[_vm._v(_vm._s(item.quantidade_parcelas))])]}},{key:"item.valor_total",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"num_margin",style:([5, 20].includes(item.status_code) ? 'color:red': '')},[_vm._v(_vm._s(_vm.$stringFormat.formatNumber(item.valor_total, 2)))])]}},{key:"item.desconto",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"num_margin",style:([5, 20].includes(item.status_code) ? 'color:red': '')},[_vm._v(_vm._s(_vm.$stringFormat.formatNumber(item.desconto, 2)))])]}},{key:"item.despesas",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"num_margin",style:([5, 20].includes(item.status_code) ? 'color:red': '')},[_vm._v(_vm._s(_vm.$stringFormat.formatNumber(item.despesas, 2)))])]}},{key:"item.frete",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"num_margin",style:([5, 20].includes(item.status_code) ? 'color:red': '')},[_vm._v(_vm._s(_vm.$stringFormat.formatNumber(item.frete, 2)))])]}},{key:"item.numero_nf",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"num_margin",style:([5, 20].includes(item.status_code) ? 'color:red': '')},[_vm._v(" "+_vm._s(item.numero_nf || '-')+" ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('VisualizaSolicitacaoProdutoComponents',{attrs:{"compra":item},on:{"entregue":_vm.getData}})],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }