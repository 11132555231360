<template>
  <v-card color="transparent" flat>
    <v-card-title>
      Dashboards
    </v-card-title>
    <v-row>
      <template v-for="dashboard in dashboards">
        <template v-for="child in dashboard.children">
          <v-col v-if="$auth.hasPermission(child.gate)" cols="12" md="2" :key="child.gate">
            <v-card class="lighten-4" :color="dashboard.color" :to="child.route">
              <v-card-text style="min-height: 120px">
                <v-card class="d-flex align-center justify-center" color="transparent" flat>
                  <v-card color="transparent" flat>
                    <v-card-text class="text-center pb-0 pt-1">
                      <v-avatar x-large>
                        <v-icon
                            :color="dashboard.color"
                            large
                        >
                          {{ child.icon }}
                        </v-icon>
                      </v-avatar>
                    </v-card-text>
                    <v-card-text class="subtitle-2 black--text text-center py-0">
                      {{ child.title }}
                    </v-card-text>
                  </v-card>
                </v-card>
              </v-card-text>
              <v-card-actions :class="dashboard.color">
                <v-spacer />
                <div class="subtitle-2 white--text">{{ dashboard.title }}</div>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-col>
        </template>
      </template>
    </v-row>
  </v-card>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

export default {
  name: 'dashboards',

  data: () => ({
    dashboards: [{
      title: 'Agrícola',
      color: 'green',
      textColor: 'green--text',
      children: [{
        gate: 'tela.bi.agricola.carregamentos',
        icon: 'mdi-truck',
        route: 'bi/agricola/carregamentos',
        title: 'Carregamentos',
      }, {
        gate: 'tela.bi.agricola.meta.producoes',
        icon: 'mdi-grass',
        route: 'bi/agricola/meta_producoes',
        title: 'Metas de Produção',
      }],
    }, {
      title: 'Comercial',
      color: 'indigo',
      textColor: 'indigo--text',
      children: [{
        gate: 'tela.bi.comercial.previsao.vendas',
        icon: 'mdi-finance',
        route: 'bi/comercial/previsao_vendas',
        title: 'Previsão de Vendas 2025',
      }, {
        gate: 'tela.bi.comercial.carteira.clientes.geral',
        icon: 'mdi-briefcase-account',
        route: 'bi/comercial/carteira_clientes_geral',
        title: 'Carteira Clientes - Geral',
      }, {
        gate: 'tela.bi.comercial.carteira.clientes.av',
        icon: 'mdi-briefcase-account',
        route: 'bi/comercial/carteira_clientes_av',
        title: 'Carteira Clientes - AV',
      }, {
        gate: 'tela.bi.comercial.carteira.clientes.rtv',
        icon: 'mdi-briefcase-account',
        route: 'bi/comercial/carteira_clientes_rtv',
        title: 'Carteira Clientes - RTV',
      }, {
        gate: 'tela.bi.comercial.mapas',
        icon: 'mdi-map',
        route: 'bi/comercial/mapas',
        title: 'Mapas',
      }, {
        gate: 'tela.bi.comercial.visitas',
        icon: 'mdi-account-multiple-plus',
        route: 'bi/comercial/visitas',
        title: 'Visitas',
      }, {
        gate: 'tela.bi.comercial.comissoes',
        icon: 'mdi-hand-coin',
        route: 'bi/comercial/comissoes',
        title: 'Comissões',
      }, {
        gate: 'tela.bi.comercial.metas',
        icon: 'mdi-chart-line',
        route: 'bi/comercial/metas',
        title: 'Metas de Vendas',
      }, {
        gate: 'tela.bi.comercial.leads',
        icon: 'mdi-account-filter',
        route: 'bi/comercial/leads',
        title: 'Leads',
      }, {
        gate: 'tela.bi.comercial.oportunidades',
        icon: 'mdi-cash-multiple',
        route: 'bi/comercial/oportunidades',
        title: 'Oportunidades',
      }],
    }, {
      title: 'Compras',
      color: 'purple',
      textColor: 'purple--text',
      children: [{
        gate: 'tela.bi.compras',
        icon: 'mdi-basket',
        route: 'bi/compras',
        title: 'Compras',
      }],
    }, {
      title: 'Financeiro',
      color: 'grey',
      textColor: 'grey--text',
      children: [{
        gate: 'tela.bi.financeiro.meta.ebitda',
        icon: 'mdi-finance',
        route: 'bi/financeiro/meta_ebitda',
        title: 'Meta EBITDA',
      }],
    }, {
      title: 'Logística',
      color: 'red',
      textColor: 'red--text',
      children: [{
        gate: 'tela.bi.logistica',
        icon: 'mdi-dolly',
        route: 'bi/logistica',
        title: 'Logística',
      }],
    }, {
      title: 'RH',
      color: 'amber',
      textColor: 'amber--text',
      children: [{
        gate: 'tela.bi.rh.pagamentos',
        icon: 'mdi-human-male-board-poll',
        route: 'bi/rh/pagamentos',
        title: 'Pagamentos',
      }],
    }],
  }),

  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Dashboards' }])

    ApiService.get('loggedin')
  },
}
</script>
