<template>
  <div>
    <v-toolbar flat class="d-flex flex-row-reverse pb-5" color="rgba(0,0,0,0)">
      <v-menu
          :close-on-content-click="false"
          :nudge-width="400"
          v-if="permiteManipularConfiguracoes"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              color="primary"
              dark
              class="mr-3"
              title="Configuracoes"
              v-bind="attrs"
              v-on="on"
          >
            <v-icon>mdi-cog</v-icon>
          </v-btn>
        </template>
        <v-card class="pt-4">
          <v-col class="pb-0 col-12">
            <ProdutoSelect nome="cobertura" v-model="cobertura" :empresa="filtro.empresaId" :producao="filtro.producaoId" insumos label="Cobertura" multiple @change="atualizaConfiguracoes(1, $event)"></ProdutoSelect>
          </v-col>
          <v-col class="pb-0 col-12">
            <ProdutoSelect nome="fosforo" v-model="fosforo" :empresa="filtro.empresaId" :producao="filtro.producaoId" insumos label="Fósforo" multiple @change="atualizaConfiguracoes(2, $event)"></ProdutoSelect>
          </v-col>
          <v-col class="pb-0 col-12">
            <ProdutoSelect nome="corretivo" v-model="corretivo" :empresa="filtro.empresaId" :producao="filtro.producaoId" insumos label="Corretivo" multiple @change="atualizaConfiguracoes(3, $event)"></ProdutoSelect>
          </v-col>
        </v-card>
      </v-menu>
      <v-btn
          color="primary"
          dark
          class="mr-3"
          title="Remover Filtros"
          @click="removeFiltros"
      >
        <v-icon>mdi-filter-remove</v-icon>
      </v-btn>
      <HeaderControl v-model="model" :headers="headersOptions" :local-storage-save-name="localStorageSaveName"></HeaderControl>
      <Exportar
        :filtro="filtro"
        :disabled="!filtro.empresaId || !filtro.producaoId"
        :exports="exports"></Exportar>
    </v-toolbar>
  </div>
</template>

<script>

import ApiService from '@/core/services/api.service'
import { can } from '@/core/services/storage.service'

export default {
  props: {
    value: {
      type: Object,
    },
    filtro: {
      type: Object,
    },
    headersOptions: {
      type: Array,
    },
    urlPdf: {
      type: String,
      default: '',
    },
    relatorioExcel: {
      type: String,
      default: '',
    },
    localStorageSaveName: {
      type: String,
    },
  },
  data: () => {
    return {
      cobertura: [],
      fosforo: [],
      corretivo: [],
      exports: [
        {
          text: 'Histórico de Talhão PDF',
          tipo: 'excel-pdf',
          acao: 'RelatorioAplicacaoProduto',
        },
        {
          text: 'Histórico de Talhão Excel',
          tipo: 'excel',
          acao: 'RelatorioAplicacaoProduto',
        },
        {
          text: 'Data de Adubação PDF',
          tipo: 'excel-pdf',
          acao: 'RelatorioAplicacaoGeral',
        },
        {
          text: 'Data de Adubação Excel',
          tipo: 'excel',
          acao: 'RelatorioAplicacaoGeral',
        },
        {
          text: 'Cobertura PDF',
          tipo: 'excel-pdf',
          acao: 'RelatorioAplicacaoCobertura',
        },
        {
          text: 'Cobertura Excel',
          tipo: 'excel',
          acao: 'RelatorioAplicacaoCobertura',
        },
        {
          text: 'Cobertura Excel Horizontal',
          tipo: 'excel',
          acao: 'RelatorioAplicacaoCoberturaHorizontal',
        },
        {
          text: 'Fósforo PDF',
          tipo: 'excel-pdf',
          acao: 'RelatorioAplicacaoFosforo',
        },
        {
          text: 'Fósforo Excel',
          tipo: 'excel',
          acao: 'RelatorioAplicacaoFosforo',
        },
        {
          text: 'Fósforo Excel Horizontal',
          tipo: 'excel',
          acao: 'RelatorioAplicacaoFosforoHorizontal',
        },
        {
          text: 'Corretivo PDF',
          tipo: 'excel-pdf',
          acao: 'RelatorioAplicacaoCorretivo',
        },
        {
          text: 'Corretivo Excel',
          tipo: 'excel',
          acao: 'RelatorioAplicacaoCorretivo',
        },
        {
          text: 'Corretivo Excel Horizontal',
          tipo: 'excel',
          acao: 'RelatorioAplicacaoCorretivoHorizontal',
        },
      ],
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
    permiteManipularConfiguracoes: function () {
      return can('agricola.configuracoes.update')
    },
  },
  mounted () {
    this.buscaConfiguracoes()
  },
  methods: {
    buscaConfiguracoes () {
      ApiService.get('/agricola/configuracoes/1').then((response) => {
        this.cobertura = response.data.valor
        this.changeConfiguracoes()
      })

      ApiService.get('/agricola/configuracoes/2').then((response) => {
        this.fosforo = response.data.valor
        this.changeConfiguracoes()
      })

      ApiService.get('/agricola/configuracoes/3').then((response) => {
        this.corretivo = response.data.valor
        this.changeConfiguracoes()
      })
    },
    atualizaConfiguracoes (tipo, value) {
      ApiService.put('/agricola/configuracoes/' + tipo, {
        valor: value,
      }).then((response) => {
        console.log(response.data.message)
      })

      this.changeConfiguracoes()
    },
    changeConfiguracoes () {
      this.$emit('changeConfiguracoes', {
        cobertura: this.cobertura,
        fosforo: this.fosforo,
        corretivo: this.corretivo,
      })
    },
    removeFiltros () {
      this.$router.push({ query: null })
      const produtos = {}
      window.localStorage.setItem('produtos-padroes', JSON.stringify(produtos))
      window.location.reload()
    },
  },
}
</script>

<style scoped>

</style>
